import { Component, Input, OnInit, ViewChild, NgZone } from "@angular/core";
import { registerTheme } from '@siemens/ix-echarts';
import { alarmsData } from "src/types/alerts.types";
import * as echarts from 'echarts/core';
import { EChartsOption } from 'echarts';
import { Store } from "@ngrx/store";
import * as fromThemeSelectors from "../../app/store/selectors/themeswitcher.selector";
import * as alarmsSelector from "../../app/store/selectors/alarms.selector";
import { AppState } from "../store/app.state";
import { Subject, catchError, distinctUntilChanged, of, takeUntil } from "rxjs";



@Component({
  selector: "app-piechart",
  templateUrl: "./piechart.component.html",
  styleUrls: ["./piechart.component.scss"],
})
export class PiechartComponent implements OnInit {

  @ViewChild('chart') chartComponent: any;

  legendColor: string = '#000000';

  @Input() alarmsData: any;

  private unsubscribe$ = new Subject<void>();

  showComponent = true;


  constructor(
    private zone: NgZone,
    private store: Store<AppState>,
  ) {
  }

  refreshComponent(): void {
    this.showComponent = false;
    setTimeout(() => {
      this.showComponent = true;
    }, 1300); // delay can be adjusted as needed
  }
  

  ngOnInit(): void {
    registerTheme(echarts);
    this.store.select(fromThemeSelectors.selectThemeToken).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(token => {
      this.updateChartStyles(token);
    });

    this.store.select(alarmsSelector.selectAlarms).pipe(
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$),
      catchError(err => {
        console.error("Error while fetching alarms:", err);
        return of(null);  // Return a null observable which can be checked later
      })
    ).subscribe(alarmsData => {
      if (alarmsData) {
        this.alarmsData = alarmsData;
        this.calculateAlarms();
      }
    });
  }


  option: EChartsOption = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      orient: 'vertical',
      left: 'right',
      textStyle: {
        color: this.legendColor
      },
      itemGap: 14,
      formatter: function (name) {
        return echarts.format.truncateText(name, 249, '14px Microsoft Yahei', '…');
      },
      tooltip: {
        show: true
      }
    },
    series: [
      {
        name: 'Alert description',
        type: 'pie',
        radius: '95%',
        label: {
          show: false
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          }
        }
      }
    ]
  };


  // to calculate the value of each alarm
  calculateAlarms(): void {
    // Check if alarmsData is properly structured
    if (!this.alarmsData?.data?.Values) {
      console.error("Invalid alarms data structure:", this.alarmsData);
      return;
    }

    let alarmCounts: { [description: string]: number } = {};

    this.alarmsData.data.Values.forEach((device: alarmsData) => {
      // Safely handle potentially undefined arrays
      const hostAlarms = device.DynamicHostDeviceAlarms || [];
      const moduleAlarms = device.ModuleAlarmsDynamic || [];
      
      [...hostAlarms, ...moduleAlarms].forEach(alarm => {
        if (alarm && alarm.Description) {
          if (!alarmCounts[alarm.Description]) {
            alarmCounts[alarm.Description] = 0;
          }
          alarmCounts[alarm.Description]++;
        }
      });
    });

    const data = Object.entries(alarmCounts).map(([name, value]) => ({ name, value: Number(value) }));

    // Update the series data
    if (Array.isArray(this.option.series) && this.option.series[0]) {
      (this.option.series[0] as any).data = data;
    }

    // Wrap the chart update in setTimeout to avoid rendering conflicts
    setTimeout(() => {
      this.updateChart();
    }, 0);
  }


  updateChart(): void {
    // Update the chart
    this.zone.run(() => {  // Ensure update runs within Angular's zone
      if (this.chartComponent && this.chartComponent.chartInstance) {
        this.chartComponent.chartInstance.setOption(this.option, true);
      }
    });
  }

  updateChartStyles(token: string | null): void {
    this.legendColor = token === 'theme-brand-dark' ? '#ffffff' : '#000000';
    // Create a new option object
    this.option = {
      ...this.option,
      legend: {
        ...this.option.legend,
        textStyle: {
          color: this.legendColor
        }
      }
    };
    this.updateChart();
  }
}
